import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { UsersComponent } from './usermanagement/users/users.component';
import { ClientsComponent } from './clientmanagement/clients/clients.component';
import { AdduserComponent } from './usermanagement/AddUser/adduser/adduser.component';
import { UpdateuserComponent } from './usermanagement/updateuser/updateuser.component';
import { AddclientComponent } from './clientmanagement/addclient/addclient.component';
import { AuthorizationService } from './common/authorization/authorization.service';
import { UnauthorizedComponent } from './common/exception/unauthorized.component';
const routes: Routes = [
  {path: '', redirectTo: '/users', pathMatch: 'full',canActivate: [AuthorizationService]},
  { path: 'home', component: HomepageComponent,canActivate: [AuthorizationService]},
  { path: 'users', component: UsersComponent,canActivate: [AuthorizationService]},
  { path: 'clients', component: ClientsComponent,canActivate: [AuthorizationService]},
  { path: 'adduser', component: AdduserComponent,canActivate: [AuthorizationService]},
  { path: 'edituser', component: UpdateuserComponent,canActivate: [AuthorizationService]},
  { path: 'addclient', component: AddclientComponent,canActivate: [AuthorizationService]},
  { path: 'addclient/:id', component: AddclientComponent,canActivate: [AuthorizationService]},
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: '**', redirectTo: '/users',canActivate: [AuthorizationService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.scss'],
})
export class UpdateuserComponent implements OnInit {
  edituserForm: FormGroup;
  isInternal: boolean = false;
  isDisplay: boolean = false;
  isDisplayAll: boolean = false;
  userDetailsResponse: any;
  userTypes: any[] = [];
  roles: any;
  clientsList: any = [];
  firstNameError: any;
  lastNameError: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private myglobalobj: MyGlobalObject,
    private formbuilder: FormBuilder,
    private globalService: GlobalService,
    public toastr: ToastrManager
  ) {
    this.edituserForm = this.formbuilder.group({
      userType: [''],
      guid: [''],
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.email]],
      role: [null],
      clients: [null],
    });
  }

  ngOnInit(): void {
    this.fetchRefData();
    this.firstNameError = 'First name is required';
    this.lastNameError = 'Last name is required';
  }
  public selectionChange(value: any): void {
    if (value.text == 'Super Admin') {
      this.isDisplayAll = true;
      this.isDisplay = false;
    } else {
      this.isDisplay = true;
      this.isDisplayAll = false;
    }
  }
  fetchRefData() {
    this.globalService
      .fetchRefData()
      .then((res) => {
        let rolesDB = res.ApplicationRoleModel;
        let userTypesDB = res.UserTypeModel;
        let clientsListDB = res.ClientDetailsModel;
        this.userTypes = userTypesDB.map((obj: any) => {
          let userTypetemp = {
            text: '',
            value: '',
          };
          userTypetemp['text'] = obj.UserTypeName;
          userTypetemp['value'] = obj.UserTypeId;
          return userTypetemp;
        });
        this.clientsList = clientsListDB.map((obj: any) => {
          let clientstemp = {
            text: '',
            value: '',
          };
          clientstemp['text'] = obj.ClientEntityName;
          clientstemp['value'] = obj.ClientId;
          return clientstemp;
        });
        this.roles = rolesDB.map((obj: any) => {
          let rolestemp = {
            text: '',
            value: '',
          };
          rolestemp['text'] = obj.Role;
          rolestemp['value'] = obj.RoleId;
          return rolestemp;
        });
        this.getUserDetailById();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getUserDetailById() {
    this.globalService
      .getUserDetailById(this.myglobalobj.viewUserMailId)
      .then((res) => {
        this.userDetailsResponse = res;
        let clientsadd;
        if (res.ClientId != '')
          clientsadd = res.ClientId.split(`,`).map(Number);
        else clientsadd = null;

        this.edituserForm.controls['userType'].setValue(res.UserTypeId);
        this.edituserForm.controls['guid'].setValue(res.UserGUID);
        this.edituserForm.controls['firstName'].setValue(res.FirstName);
        this.edituserForm.controls['lastName'].setValue(res.LastName);
        this.edituserForm.controls['email'].setValue(res.EmailId);
        this.edituserForm.controls['role'].setValue(res.RoleId);
        this.edituserForm.controls['clients'].setValue(clientsadd);
        this.edituserForm.controls['email'].disable();
        this.edituserForm.controls['userType'].disable();
        this.edituserForm.controls['guid'].disable();
        if (res.RoleId == 1) {
          this.isDisplayAll = true;
          this.isDisplay = false;
        } else {
          this.isDisplay = true;
          this.isDisplayAll = false;

          var filterroles = [];
          var index = this.userTypes.findIndex(
            (a) => a.value == res.UserTypeId
          );
          if (this.userTypes[index].text == 'External') {
            for (let i = 0; i < this.roles.length; i++) {
              if (this.roles[i].text != 'Super Admin') {
                filterroles.push(this.roles[i]);
              }
            }
          } else if (
            this.myglobalobj.userDetailsEnlightenAdmin == 'Super Admin'
          ) {
            for (let i = 0; i < this.roles.length; i++) {
              filterroles.push(this.roles[i]);
            }
          } else if (
            this.myglobalobj.userDetailsEnlightenAdmin != 'Super Admin'
          ) {
            for (let i = 0; i < this.roles.length; i++) {
              if (this.roles[i].text != 'Super Admin') {
                filterroles.push(this.roles[i]);
              }
            }
          }
          this.roles = filterroles;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveUser() {
    let clientVal, userTypeVal, roleIdVal;

    var formObject = this.edituserForm.getRawValue();
    const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;

    var firstError = specialChars.split('').some((specialChar) => {
      if (formObject.firstName.includes(specialChar)) {
        return true;
      } else return false;
    });
    if (firstError) {
      this.firstNameError = 'Please enter name without special characters';
      this.edituserForm.controls['firstName'].setErrors({ incorrect: true });
      return;
    }
    var lastError = specialChars.split('').some((specialChar) => {
      if (formObject.lastName.includes(specialChar)) {
        return true;
      } else return false;
    });
    if (lastError) {
      this.lastNameError = 'Please enter name without special characters';
      this.edituserForm.controls['lastName'].setErrors({ incorrect: true });
      return;
    }
    if (formObject.clients == null) {
      clientVal = '';
    } else {
      clientVal = formObject.clients.toString();
    }
    if (formObject.role.value == 1) {
      clientVal = '';
    }
    if (formObject.userType.value == undefined) {
      userTypeVal = formObject.userType;
    } else {
      userTypeVal = formObject.userType.value;
    }
    if (formObject.role.value == undefined) {
      roleIdVal = formObject.role;
    } else {
      roleIdVal = formObject.role.value;
    }

    var createdOn = new Date();
    var dd = createdOn.toISOString();
    let userObject = {
      UserID: this.userDetailsResponse.UserId,
      UserGUID: formObject.guid,
      FirstName: formObject.firstName,
      LastName: formObject.lastName,
      EmailID: formObject.email,
      RoleID: roleIdVal,
      UserTypeID: userTypeVal,
      ClientId: clientVal,
      ClientName: '',
      CreatedBy: this.userDetailsResponse.CreatedBy,
      CreatedOn: this.userDetailsResponse.CreatedOn,
      ModifiedBy: this.myglobalobj.loggedinUserDetail.mail,
      ModifiedOn: dd,
      IsActive: true,
      IsMasterUser: this.userDetailsResponse.IsMasterUser,
    };

    this.globalService
      .updateUser(userObject)
      .then((res) => {
        if (res) {
          this.globalService.navigateTo('/users');
          this.toastr.successToastr('Updated successfully', 'Success');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  cancelClick() {
    this.globalService.navigateTo('/users');
  }
  ngOnDestroy() {
    this.myglobalobj.viewUserMailId = '';
  }
}

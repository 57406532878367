import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../globalservice/globalservice.service';
import { Observable, of } from 'rxjs';
import getPkce from 'oauth-pkce';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private cookieService: CookieService,
    private globalService: GlobalService
  ) {}

  checkAuthentication(): Observable<any> {
    var challengeVerifier = { verifier: '', challenge: '' };
    let codeURL;
    let token;
    if (window.location.href.includes('?code=')) {
      codeURL = window.location.href;

      return this.convertSuccessfulLoginToJson(codeURL);
    }

    let cookie: any = localStorage.getItem('OPENAMSESSION');

    if (
      cookie === undefined ||
      cookie == 'undefined' ||
      cookie == 'null' ||
      cookie === null
    ) {
      if (
        token === undefined ||
        token === null ||
        token === '' ||
        token == 'undefined' ||
        token == 'null'
      ) {
        getPkce(50, (error, { verifier, challenge }) => {
          if (!error) {
            // console.log({ verifier, challenge });
            challengeVerifier = { verifier, challenge };
            //console.log(challengeVerifier);
            localStorage.setItem('verifier', challengeVerifier.verifier);
            this.performLogin(challengeVerifier.challenge);
          }
        });
      }
    } else {
      token = JSON.parse(cookie);
    }
    return of(token);
  }

  performLogin(challenge: string) {
    // let endpoint = environment.openam.authorization_endpoint;
    // let url = `${endpoint}?response_type=code&client_id=${
    //   environment.openam.client_id
    // }&redirect_uri=${encodeURIComponent(
    //   environment.openam.app_url
    // )}&scope=profile+openid+email`;
    // window.location.href = url;
    var openAmURL = `${
      environment.openam.authorization_endpoint
    }?response_type=code&client_id=${
      environment.openam.client_id
    }&redirect_uri=${encodeURIComponent(
      environment.openam.app_url
    )}&scope=openid+openid+email+preferredMail&code_challenge=${challenge}&code_challenge_method=S256`;
    window.location.href = openAmURL;
  }

  convertSuccessfulLoginToJson(codeURL: any) {
    // Once authenticated add token to session storage and open application home page

    let url = codeURL;
    let startindex = url.indexOf('=');
    let endIndex = url.indexOf('&iss');
    let code = url.slice(startindex + 1, endIndex);
    return this.globalService.getTokenDetails(code);
  }

  async getUserInfo(sessiondetails: any) {
    var result;
    //const token = JSON.parse(this.cookieService.get('OPENAMSESSION'));
    let tokenValue: any = localStorage.getItem('OPENAMSESSION');
    if (sessiondetails) {
      this.globalService
        .getOpenAMUserInfo(
          environment.openam.user_profile_url +
            '?access_token=' +
            sessiondetails.access_token,
          {},
          sessiondetails.access_token
        )
        .then((response) => {
          if (!response.ok) {
            var challengeVerifier = { verifier: '', challenge: '' };
            getPkce(50, (error, { verifier, challenge }) => {
              if (!error) {
                // console.log({ verifier, challenge });
                challengeVerifier = { verifier, challenge };
                //console.log(challengeVerifier);
                localStorage.setItem('verifier', challengeVerifier.verifier);
                this.performLogin(challengeVerifier.challenge);
              }
            });
          }
        });

      let token = JSON.parse(tokenValue).id_token;
      const user = <any>jwt_decode(token);
      const firstName = user.given_name
        ? user.given_name
        : this.getFirstName(user.upn);
      const lastName = user.family_name
        ? user.family_name
        : this.getLastName(user.upn);

      result = {
        given_name: firstName,
        family_name: lastName,
        unique_name: user.name ? user.name : firstName + ' ' + lastName,
        role: user.role,
        mail: user.preferredMail,
        guid: user.sub || user.uid,
        claims: null,
      };
    }

    return result;
  }

  getFirstName(varEmailAddress: string) {
    const emailAddressWithoutDomain = varEmailAddress.split('@')[0];
    let firstName = emailAddressWithoutDomain.substr(
      0,
      emailAddressWithoutDomain.indexOf('.')
    );
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    return firstName;
  }

  getLastName(varEmailAddress: string) {
    const emailAddressWithoutDomain = varEmailAddress.split('@')[0];
    let lastName = emailAddressWithoutDomain.substr(
      emailAddressWithoutDomain.indexOf('.') + 1,
      emailAddressWithoutDomain.length - emailAddressWithoutDomain.indexOf('.')
    );
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    return lastName;
  }
}

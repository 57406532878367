import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../globalservice/globalservice.service';
import { MyGlobalObject } from '../myglobalobject';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    private globalService: GlobalService,
    public myGlobalObj: MyGlobalObject
  ) {
    // Check and resolve Local configuration file read issue
    // this.globalService.fetchLabelConfigs().then(response => this.myGlobalObject.labelConfigs = response);
  }

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-addclient',
  templateUrl: './addclient.component.html',
  styleUrls: ['./addclient.component.scss'],
})
export class AddclientComponent implements OnInit {
  addclientForm: FormGroup;
  id: any;
  isEdit: boolean = false;
  editClientDetails: any;
  clientsList: any;
  public selectDbName: string | undefined;
  constructor(
    private formbuilder: FormBuilder,
    private globalService: GlobalService,
    public toastr: ToastrManager,
    private activatedRoute: ActivatedRoute,
    public myGlobalObject: MyGlobalObject
  ) {
    this.addclientForm = this.formbuilder.group({
      clientEntityName: [''],
      fromDB: [''],
    });
  }

  ngOnInit(): void {
    let id: number;
    this.activatedRoute.params.subscribe((params) => {
      this.id = params.id;
      id = params.id;
      if (id) {
        this.isEdit = true;
        this.editPage(id);
      }
    });
    this.fetchClientsList();
  }
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  editPage(id: any) {
    this.globalService
      .getClientDetailById(id)
      .then((res) => {
        this.editClientDetails = res;
        this.addclientForm.controls['clientEntityName'].setValue(
          res.ClientEntityName
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveClient() {
    let clientObject = {},
      message = '';

    var formObject = this.addclientForm.getRawValue();
    var createdOn = new Date();
    var dd = createdOn.toISOString();

    if (this.isEdit) {
      clientObject = {
        ClientEntityName: formObject.clientEntityName,
        IsActive: this.editClientDetails.IsActive,
        CreatedBy: this.editClientDetails.CreatedBy,
        CreatedOn: dd,
        ModifiedBy: this.myGlobalObject.loggedinUserDetail.mail,
        ModifiedOn: dd,
        DBName: this.editClientDetails.DBName,
        ClientId: this.editClientDetails.ClientId,
        DBCreationStatusId: this.editClientDetails.DBCreationStatusId,
        FromDbName: this.editClientDetails.FromDbName,
      };
      message = 'Updated successfully';
      this.globalService
        .updateClient(clientObject)
        .then((res) => {
          if (res) {
            
            if (res == 'Client details already exists')
            {
              this.toastr.errorToastr(res, 'Failed');
            }
              else  if (res == 'Client name already exists')
              {
              this.toastr.errorToastr(res, 'Failed');
              }
            else 
            {this.toastr.successToastr(res, 'Success');
            this.globalService.navigateTo('/clients');}
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      clientObject = {
        ClientEntityName: formObject.clientEntityName,
        IsActive: true,
        CreatedBy: this.myGlobalObject.loggedinUserDetail.mail,
        CreatedOn: dd,
        ModifiedBy: '',
        ModifiedOn: dd,
        DBName: '',
        FromDbName: this.selectDbName == undefined ? null : this.selectDbName,
      };
      message = 'Updated successfully';
      this.globalService
        .saveClient(clientObject)
        .then((res) => {
          if (res) {
            if (res == 'Client details already exists')
            {
              this.toastr.errorToastr(res, 'Failed');
            }
              else  if (res == 'Client name already exists')
              {
              this.toastr.errorToastr(res, 'Failed');
              }
            else {
              this.globalService.navigateTo('/clients');
              this.toastr.successToastr(res, 'Success');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  cancelClick() {
    this.globalService.navigateTo('/clients');
  }

  public selectionChangeType(value: any): void {
    if (value != undefined) this.selectDbName = value.DBName;
  }

  fetchClientsList() {
    this.globalService.fetchAllClients().then((response) => {
      this.clientsList = response;
    });
  }
}

<div class="">
  <kendo-appbar position="top">
    <kendo-appbar-section>
      <img
        class="sepspace"
        width="42"
        height="16"
        src="./assets/images/Logo.png"
        title="PricewaterhouseCoopers"
      />
    </kendo-appbar-section>
    <kendo-appbar-section>
      <p class="customAppkitStyleMenuItem">Transaction Data Assist Admin</p>
    </kendo-appbar-section>
    <kendo-appbar-spacer width="40px"></kendo-appbar-spacer>
    <kendo-appbar-section class="appbar-items">
      <kendo-menu (select)="handleSelectionChange($event)">
        <kendo-menu-item text="Users" cssClass="customAppkitStyleMenuItem">
        </kendo-menu-item>
        <kendo-menu-item text="Clients" cssClass="customAppkitStyleMenuItem">
        </kendo-menu-item>
      </kendo-menu>
    </kendo-appbar-section>
    <kendo-appbar-spacer></kendo-appbar-spacer>
    <kendo-appbar-section>
      <p style="text-decoration: underline; margin-top: 10px">
        <kendo-menu
          (select)="clickEnlightenPortal($event)"
          title="Click here to navigate into Transaction Data Assist portal"
        >
          <kendo-menu-item
            text="Transaction Data Assist portal"
            cssClass="customAppkitStyleMenuItem linkStyle"
          ></kendo-menu-item>
        </kendo-menu>
      </p>
    </kendo-appbar-section>
    <kendo-appbar-section class="actions">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        style="background-color: #f2f2f2"
      >
        <kendo-avatar
          [initials]="initials"
          shape="circle"
          width="26px"
          height="26px"
        ></kendo-avatar>
      </button>
      <mat-menu
        #menu="matMenu"
        style="position: absolute; top: 58px; right: 0px"
      >
        <button mat-menu-item (click)="helpClick()">
          Transaction Data Assist help
        </button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </kendo-appbar-section>
  </kendo-appbar>
  <!-- <mat-toolbar  class="ad-toolbar navbar-fixed-top">
        <img class="toolbarimg sepspace" src="./assets/images/pwc.jpg" title="PricewaterhouseCoopers"  />
        <mat-divider class="sep sepdark sepspace xshide divider"></mat-divider>
        <span class="sepspace toolbartitle"></span>
        <span class="spacer" style="font-weight:bold">Welcome {{this.user.given_name}}</span>
        
            <mat-icon (click)="logout()" class="colorRed point">power_settings_new</mat-icon>
            <span class="point"
             >logout</span>
         
    </mat-toolbar>-->
  <!-- <mat-toolbar>
    <img
      class="toolbarimg sepspace"
      src="./assets/images/pwc.jpg"
      title="PricewaterhouseCoopers"
    />
    <span class="gap"><h2>Enlighten Admin</h2></span>
    <span class="filler"></span>
    <span class="gapright pwc-color"
      ><h3>Welcome {{ this.user.unique_name }}</h3></span
    >
    <mat-icon (click)="logout()" class="colorRed point pwc-color"
      >power_settings_new</mat-icon
    >
    <span class="pwc-color">Logout</span>
  </mat-toolbar> -->
  <!-- <mat-toolbar class="pwc-background-toolbar">
        <mat-toolbar-row>
          
          
          <span class="menu-spacer"></span>
          <div>
            <a mat-button class="pwc-text-white noborder" routerLinkActive="active" [routerLink]="'/users'"> Users </a>
            <a mat-button class="pwc-text-white noborder" routerLinkActive="active" [routerLink]="'/clients'"> Clients </a>
            
      
          </div>
        </mat-toolbar-row>
      
        
      </mat-toolbar>-->
  <div class="enlightenDialog">
    <div kendoDialogContainer></div>
  </div>
  <router-outlet></router-outlet>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
//import { MyGlobalObject } from '../MyGlobalObject';
import { environment } from '../../../environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CookieService } from 'ngx-cookie-service';
import { ResponseType } from '@angular/http';
import { MyGlobalObject } from '../myglobalobject';
import { anyChanged } from '@progress/kendo-angular-common';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // apiPort: string;
  private params: any;
  //private getArgs: RequestOptionsArgs;
  //private postArgs: RequestOptionsArgs;
  //private putArgs: RequestOptionsArgs;
  //private deleteArgs: RequestOptionsArgs;
  //private formDataPostArgs: RequestOptionsArgs;
  private baseUrl: string;
  private apifolder: string;
  public apiUrl: any;
  private mockApiUrl: any;
  private apiHost: string;
  public tokenValue: any;
  constructor(
    public http: HttpClient,
    public myGlobalObject: MyGlobalObject,
    private router: Router,
    private cookieService: CookieService //  private toastr: ToastrManager
  ) {
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    this.apiHost = '';
    this.baseUrl = environment.baseUrl;
    //  this.apiPort = environment.apiPort;
    this.apifolder = environment.apifolder;
    //Api urls
    this.apiUrl = {
      getLoggedInUserDetails: '/UserManagement/GetLoggedInUserRole',
      fetchRefData: '/UserManagement/GetRoleUserTypeClientDetails',
      ViewUserById: '/UserManagement/GetUserDetailByEmailId',
      ViewAllUsers: '/UserManagement/GetAllUserDetails',
      disableUser: '/UserManagement/ActiveInactiveUserDetail',
      deleteUser: '/UserManagement/DeleteUserDetail',
      saveUser: '/UserManagement/AddUserDetail',
      saveExternalUser: '/UserManagement/AddExternalUserDetails',
      updateUser: '/UserManagement/UpdateUserDetails',
      ViewAllClients: '/ClientManagement/GetAllClientDetails',
      saveClient: '/ClientManagement/AddClientDetails',
      viewClientById: '/ClientManagement/GetClientDetailByClientID',
      updateClientDetails: '/ClientManagement/UpdateClientDetail',
      disableClient: '/ClientManagement/DisableClient',
    };
    //mock Json url to check in local
    this.mockApiUrl = {
      refData: './assets/data/referentialData.json',
      config: './assets/data/labelConfigurations.json',
      azuredevops: './assets/data/azuredevops.json',
      permission: './assets/data/permission.json',
      loggedinuserdetails: './assets/data/userDeetails.json',
      userdata: './assets/data/userData.json',
      userSearch: './assets/data/usersearch.json',
    };
    // this.prepareRequests();
  }

  /*prepareRequests() {
    const headersObj = new Headers();
    console.log(headersObj)
    this.getArgs = {
      headers: headersObj,
      method: 'GET',
      withCredentials: false
    };
    this.postArgs = {
      headers: headersObj,
      method: 'POST',
      withCredentials: false
    };
    this.putArgs = {
      headers: headersObj,
      method: 'PUT',
      withCredentials: false
    };
    this.deleteArgs = {
      headers: headersObj,
      method: 'DELETE',
      withCredentials: false
    };
    this.formDataPostArgs = {
      headers: headersObj,
      method: 'POST',
      withCredentials: false
    };
  }*/
  //post method
  /*post(host: string, jsondata: any, args: any): Observable<any[]> {
    const varArgs = (args) ? args : {};
    return this.http.post(host, jsondata, varArgs)
      .pipe(map((response) => {
        return response.text() ? response.json() : {};
      }));
  }*/
  //openAM Integration
  getOpenAMUserInfo(
    varURL: string,
    params: {},
    access_token: any
  ): Promise<any> {
    return this.postUserInfo(varURL, params, access_token)
      .toPromise()
      .then(
        (response) => {
          return response;
        },
        (err) => {
          console.log(err);
          return err;
        }
      );
  }
  postUserInfo(
    host: string,
    jsondata: any,
    access_token: string
  ): Observable<any> {
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + access_token,
    };
    const headersObj = new HttpHeaders(headers);
    //const headersObj = new HttpHeaders();
    // headersObj.set('Content-Type', 'application/x-www-form-urlencoded');
    //headersObj.append('Authorization', 'Bearer ' + access_token);
    return this.http
      .post(host, jsondata, {
        headers: headersObj,
        withCredentials: false,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getTokenDetails(code: any): Observable<any> {
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = environment.openam.token_endpoint;
    let body = new URLSearchParams();
    let codeVerifier: any = localStorage.getItem('verifier');
    body.set('code', code);
    body.set('client_id', environment.openam.client_id);
    body.set('code_verifier', codeVerifier);
    body.set('redirect_uri', environment.openam.app_url);
    body.set('grant_type', 'authorization_code');

    // let appDetails = {
    //   code: code,
    //   client_id: environment.openam.client_id,
    //   client_secret: environment.openam.client_sst,
    //   redirect_uri: environment.openam.app_url,
    //   grant_type: 'authorization_code',
    // };

    return this.http.post<string>(this.apiHost, body, {
      headers: headersObj,
      withCredentials: false,
    });
  }
  //getlogged in userdetails
  getLoggedUserInfo(): Promise<any> {
    //let tokenV=
    this.tokenValue = localStorage.getItem('OPENAMSESSION');
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.getLoggedInUserDetails;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err.error);
        this.myGlobalObject.authorizationErrorMessage = err.error;
        this.router.navigate(['/unauthorized']);
        // return err;
      });
  }

  fetchAllClients(): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.ViewAllClients;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  fetchAllUsers(): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.ViewAllUsers;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchRefData(): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.fetchRefData;
    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //Add user details
  saveUsers(userdetails: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    if (userdetails.UserTypeID == 1) {
      this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.saveUser;
    } else {
      this.apiHost =
        this.baseUrl + this.apifolder + this.apiUrl.saveExternalUser;
    }
    return this.http
      .post(this.apiHost, userdetails, {
        headers: headersObj,
        withCredentials: false,

        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //getuserdetails by id
  getUserDetailById(email: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('emailId', email);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.ViewUserById;

    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,

        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //Update user details
  updateUser(userdetails: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.updateUser;
    return this.http
      .post(this.apiHost, userdetails, {
        headers: headersObj,
        withCredentials: false,

        responseType: 'text',
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //delete user details
  deleteUserDetailById(deleteMail: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let deletemail = deleteMail;
    let httpParams = new HttpParams().set('emailId', deletemail);

    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.deleteUser;
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //disable user details
  disableUserDetailById(mailId: any): Promise<any> {
    let httpParams = new HttpParams().set('emailId', mailId);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.disableUser;
    let options = { headers: headers };
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //Add client details
  saveClient(clientDetails: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.saveClient;
    return this.http
      .post(this.apiHost, clientDetails, {
        headers: headersObj,
        withCredentials: false,

        responseType: 'text',
      })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //get client detail by id
  getClientDetailById(id: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('clientId', id);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.viewClientById;

    return this.http
      .get(this.apiHost, {
        headers: headersObj,
        withCredentials: false,

        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //Update client details
  updateClient(clientdetails: any): Promise<any> {
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost =
      this.baseUrl + this.apifolder + this.apiUrl.updateClientDetails;
    return this.http
      .post(this.apiHost, clientdetails, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //disable client
  disableClientDetailById(id: any): Promise<any> {
    /* var headers={'Content-Type':'application/json','Authorization-Token': 'Bearer ' + JSON.parse(this.cookieService.get('OPENAMSESSION')).access_token}
    const headersObj = new HttpHeaders(headers);
    let httpParams = new HttpParams().set('clientID', id);
     
      this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.disableClient;  
  
    return this.http.post(this.apiHost,{
      headers: headersObj,      
      withCredentials: false,
      params:httpParams            
    })
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      }).catch((err) => {
        console.log(err);
      });*/
    // disableUserDetailById(mailId:any): Promise<any> {

    let httpParams = new HttpParams().set('clientID', id);
    var headers = {
      'Content-Type': 'application/json',
      'Authorization-Token':
        'Bearer ' + JSON.parse(this.tokenValue).access_token,
    };
    const headersObj = new HttpHeaders(headers);
    this.apiHost = this.baseUrl + this.apifolder + this.apiUrl.disableClient;
    let options = { headers: headers };
    return this.http
      .post(this.apiHost, null, {
        headers: headersObj,
        withCredentials: false,
        responseType: 'text',
        params: httpParams,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log('Error' + err);
      });
  }
  //general function for navigating to specified route
  navigateTo(varRoute: any) {
    const link = [varRoute];
    this.router.navigate(link);
  }
}

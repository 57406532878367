<div class="header">Enter user details</div>
<div id="mainContent" class="container-fluid" style="overflow: auto">
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <form class="k-form" [formGroup]="adduserForm">
        <fieldset class="k-form-fieldset">
          <kendo-label [for]="userType" text="User type*"></kendo-label>
          <kendo-dropdownlist
            #userType
            formControlName="userType"
            [data]="userTypes"
            [defaultItem]="{ text: 'Select', value: '' }"
            (selectionChange)="selectionChangeType($event)"
            textField="text"
            valueField="value"
            required
          >
          </kendo-dropdownlist>
          <kendo-formfield
            *ngIf="adduserForm.controls['userType'].value.value == 1"
          >
            <kendo-label [for]="guid" text="GUID*"></kendo-label>
            <input formControlName="guid" kendoTextBox #guid required />
            <kendo-formerror>{{ guidError }}</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="firstName" text="First name*"></kendo-label>
            <input
              formControlName="firstName"
              kendoTextBox
              #firstName
              required
            />
            <kendo-formerror>{{ firstNameError }}</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="lastName" text="Last name*"></kendo-label>
            <input formControlName="lastName" kendoTextBox #lastName required />
            <kendo-formerror>{{ lastNameError }}</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-label [for]="email" text="Email*"></kendo-label>
            <input formControlName="email" kendoTextBox #email required />
            <kendo-formerror>{{
              emailErrorMessage
            }}</kendo-formerror> </kendo-formfield
          ><br />
          <kendo-label [for]="role" text="Role*"></kendo-label>
          <kendo-dropdownlist
            #role
            formControlName="role"
            [data]="filterroles"
            [value]="selectedroles"
            [defaultItem]="{ text: 'Select', value: null }"
            (selectionChange)="selectionChange($event)"
            textField="text"
            valueField="value"
            required
          >
          </kendo-dropdownlist>
          <br />
          <kendo-formfield *ngIf="isDisplayAll">
            <kendo-label [for]="clients" text="Clients"></kendo-label>
            <input
              kendoTextBox
              #clients
              value="All"
              [disabled]="true"
            /> </kendo-formfield
          ><br />
          <kendo-formfield *ngIf="isDisplay">
            <kendo-label [for]="clients" text="Clients"></kendo-label>
            <kendo-multiselect
              #clients
              formControlName="clients"
              [data]="clientsList"
              textField="text"
              valueField="value"
              [valuePrimitive]="true"
            >
            </kendo-multiselect>
          </kendo-formfield>
        </fieldset>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          [btnType]="'secondary'"
          class="cancelButton"
          [label]="'Cancel'"
          (click)="cancelClick()"
        ></ap-button>
        <ap-button
          [btnType]="'primary'"
          class="okButton"
          [label]="'Save'"
          [disabled]="!adduserForm.valid"
          style="margin-right: 6px"
          themeColor="primary"
          (click)="saveUser()"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-4 col-xs-12"></div>
  </div>
  <!-- <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <mat-card class="userCard">
            <mat-card-content>
                
                <mat-card-actions align="end">
                  <button class="btn-pwc-sec smMarginRight" (click)="cancelClick()">
                    <span>Cancel</span>
                  </button>
                  <button [disabled]="!adduserForm.valid" class="btn-pwc-primary smMarginLeft floatRight" (click)="saveUser()">
                    <span>Save</span>
                  </button>
                </mat-card-actions>
            </mat-card-content>
        </mat-card>
      </div>
      <div class="col-md-3"></div>
    </div> -->
</div>

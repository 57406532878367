import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { GenericdialogComponent } from 'src/app/common/dialogs/genericdialog/genericdialog.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FabPositionMode } from '@progress/kendo-angular-buttons';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DialogAction, ActionsLayout } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public data: unknown[] = [];
  public gridView: GridDataResult | any;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public pageSize = 15;
  public skip = 0;
  public isResponsive = true;
  dialogRef!: MatDialogRef<GenericdialogComponent>;
  public multiple = false;
  public allowUnsort = true;

  public checked = true;
  public mySelection: string[] = [];
  //public disabled = true;
  public readonly = true;
  public gridData: any = [];
  public positionMode: FabPositionMode = 'fixed';

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private myglobalobj: MyGlobalObject,
    public dialog: MatDialog,
    public toastr: ToastrManager
  ) {
    this.loadgridData();
  }

  ngOnInit(): void {
    this.fetchUserData();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadgridData();
  }
  public loadgridData(): void {
    this.gridView = {
      data: orderBy(
        this.gridData.slice(this.skip, this.skip + this.pageSize),
        this.sort
      ),
      total: this.gridData.length,
    };
  }
  //sort code
  public sort: SortDescriptor[] = [
    {
      field: 'FirstName',
      dir: 'asc',
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadgridData();
  }
  //search grid
  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;

    this.gridView = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'FirstName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'LastName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'clientName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'status',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'EmailId',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadgridData();
    }
    this.dataBinding.skip = 0;
  }
  //fetch all user data list from db
  fetchUserData() {
    this.globalService.fetchAllUsers().then((response) => {
      this.gridData = response;

      this.loadgridData();
    });
  }
  //edit icon click
  editUser(data: any): void {
    this.myglobalobj.viewUserMailId = data.EmailId;
    const link = ['/edituser'];
    this.router.navigate(link);
  }
  //add button click
  adduser() {
    this.globalService.navigateTo('/adduser');
  }
  //status change toggle click
  toggleSelection(rowData: any) {
    this.globalService
      .disableUserDetailById(rowData.EmailId)
      .then((res) => {
        if (res) {
          this.toastr.successToastr('Updated successfully', 'Success');
          this.fetchUserData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //delete user
  //delete user
  public opened = false;
  public actionsLayout: ActionsLayout = 'normal';
  public deleteid: any;
  public myActions: DialogAction[] = [
    { text: 'No' },
    { text: 'Yes', themeColor: 'primary' },
  ];
  public onAction(): void {
    this.opened = false;
    this.globalService
      .deleteUserDetailById(this.deleteid)
      .then((res) => {
        this.deleteid = null;
        this.toastr.successToastr('Deleted successfully', 'Success');
        this.fetchUserData();
      })
      .catch((err) => {
        console.log(err);
        this.deleteid = null;
      });
    this.deleteid = null;
  }
  public close(status: string): void {
    this.opened = false;
  }
  public deleteUser(userData: any): void {
    this.opened = true;
    this.deleteid = userData.EmailId;
  }
  /*deleteUser(userData:any){
    
    let deleteMail=userData.EmailId;
    this.globalService.deleteUserDetailById(deleteMail).then((res) => {    
      console.log(res)
      this.toastr.successToastr(
        'Deleted successfully',
        'Success'
      );
      this.fetchUserData();
    }).catch((err) => {
      console.log(err)
    })
  }*/
}

import { Component, OnInit } from '@angular/core';
import {
  ActionsLayout,
  DialogCloseResult,
  DialogContentBase,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent extends DialogContentBase implements OnInit {
  constructor(private dialogService: DialogService, public dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {}
  cancelClick() {
    this.dialog.close();
  }
}

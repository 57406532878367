<div class="header">
  {{ isEdit ? "Edit client details" : "Enter client details" }}
</div>
<div id="mainContent" class="container-fluid" style="overflow: auto">
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <form class="k-form" [formGroup]="addclientForm">
        <fieldset class="k-form-fieldset">
          <kendo-label
            [for]="clientEntityName"
            text="Client name"
          ></kendo-label>
          <input
            formControlName="clientEntityName"
            kendoTextBox
            #clientEntityName
            required
          />
          <kendo-formerror>Client name is required</kendo-formerror>
          <kendo-formfield *ngIf="!isEdit">
            <kendo-label [for]="fromDB" text="From database"></kendo-label>
            <kendo-combobox
              [kendoDropDownFilter]="filterSettings"
              #fromDB
              formControlName="fromDB"
              [data]="clientsList"
              placeholder="Select from database..."
              (valueChange)="selectionChangeType($event)"
              textField="ClientEntityName"
              valueField="DBName"
            >
            </kendo-combobox>
          </kendo-formfield>
        </fieldset>
      </form>
      <!-- Click to Actions -->
      <div class="clickToActions" style="padding: 20px 0px">
        <ap-button
          [btnType]="'secondary'"
          class="cancelButton"
          [label]="'Cancel'"
          (click)="cancelClick()"
        ></ap-button>
        <ap-button
          [btnType]="'primary'"
          class="okButton"
          [label]="'Save'"
          [disabled]="!addclientForm.valid"
          style="margin-right: 6px"
          themeColor="primary"
          (click)="saveClient()"
        ></ap-button>
      </div>
    </div>
    <div class="col-md-6 col-xs-12"></div>
  </div>
</div>



<div class="header" style="height: 40px; margin-bottom: 0px; font-size: 20px">
  Manage Clients
</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          kendoTooltip
          title="New"
          [add]="true"
          (click)="addClient()"
        ></ap-button>
      </div>
      <kendo-grid
        [data]="gridView"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="true"
        (pageChange)="pageChange($event)"
        [(selectedKeys)]="mySelection"
        kendoGridSelectBy="id"
        class="autoHeight"
      >
        <ng-template
          [ngIf]="myglobalobj.userDetailsEnlightenAdmin === 'Super Admin'"
        >
          <kendo-grid-column
            [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
            field="actions"
            title="Actions"
            [width]="60"
            [sortable]="false"
          >
            <ng-template kendoGridCellTemplate let-data>
              <!-- <kendo-icon name="delete"   style="cursor:pointer;color: #d04a02;"></kendo-icon>-->
              <button
                *ngIf="data.IsActive == true"
                kendoButton
                name="edit"
                kendoTooltip
                title="Edit"
                (click)="editClient(data)"
              >
                <span class="Appkit4-icon icon-edit-outline"></span>
              </button>
            </ng-template>
          </kendo-grid-column>
        </ng-template>
        <kendo-grid-column
          field="ClientEntityName"
          title="Client name"
          [width]="220"
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
        >
        </kendo-grid-column>
        <ng-template
          [ngIf]="myglobalobj.userDetailsEnlightenAdmin === 'Super Admin'"
        >
          <kendo-grid-column
            field="IsActive"
            title="Client status"
            [width]="220"
            [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
          >
            <ng-template kendoGridCellTemplate let-data>
              <kendo-switch
                [(ngModel)]="data.IsActive"
                (click)="enableDisableSelection(data)"
                onLabel="Active"
                offLabel="Inactive"
                style="width: 85px"
              >
              </kendo-switch>
            </ng-template>
          </kendo-grid-column>
        </ng-template>
        <kendo-grid-column
          field="DBName"
          title="DB name"
          [width]="220"
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="DBCreationStatusId"
          title="DB status"
          [width]="220"
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
        >
          <ng-template kendoGridCellTemplate let-data>
            <p *ngIf="data.DBCreationStatusId == 1">Not Created</p>
            <p *ngIf="data.DBCreationStatusId == 2">Created</p>
            <p *ngIf="data.DBCreationStatusId == 3">
              Ready For Updating DB Details
            </p>
            <p *ngIf="data.DBCreationStatusId == 4">Db Creation Failed</p>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>

<div class="centerBox">
  <div id="error-wrapper">
    <div id="message">
      <h1>Sorry!</h1>
      <h2>{{myGlobalObj.authorizationErrorMessage}}</h2>
     <!-- <h2>
        You don't have required permission to access the Application.
        <br/>
        Please contact Customer Support.
      </h2>-->
    </div>
    <div id="bottomtrim">
      &nbsp;
    </div>
    <div id="lgo">
      <img src="../assets/images/pwc.jpg" border="0" alt="PwC logo">
    </div>
  </div>
</div>
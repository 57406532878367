<div class="header" style="height: 40px; margin-bottom: 0px; font-size: 20px">
  Manage Users
</div>
<div id="mainContent" class="container-fluid">
  <div class="row">
    <div class="col-md-12 col-xs-12">
      <div class="searchContainer">
        <ap-search
          [placeholder]="'Search here'"
          [searchType]="'secondary'"
          style="width: 100%; margin-right: 24px; padding: 0px !important"
          [disabled]="false"
          (onSearch)="onFilter($event)"
        ></ap-search>
        <ap-button
          [icon]="'plus-outline'"
          [compact]="true"
          kendoTooltip
          title="New"
          [add]="true"
          (click)="adduser()"
        ></ap-button>
      </div>
      <kendo-grid
        [data]="gridView"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="true"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        (pageChange)="pageChange($event)"
        class="autoHeight"
      >
        <kendo-grid-column
          [headerStyle]="{
            'font-weight': 'bold',
            color: '#d04a02',
            'text-align': 'left'
          }"
          field="IsActive"
          title="Actions"
          [width]="120"
          [sortable]="false"
        >
          <ng-template kendoGridCellTemplate let-data>
            <button
              *ngIf="data.IsActive == true"
              kendoButton
              name="edit"
              kendoTooltip
              title="Edit"
              (click)="editUser(data)"
              style="margin-right: 5px"
              [disabled]="data.IsMasterUser == true"
            >
              <span class="Appkit4-icon icon-edit-outline"></span>
            </button>
            <button
              kendoButton
              name="delete"
              kendoTooltip
              title="Delete"
              (click)="deleteUser(data)"
              [disabled]="data.IsMasterUser == true"
            >
              <span class="Appkit4-icon icon-delete-outline"></span>
            </button>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
          field="FirstName"
          title="First name"
          [width]="220"
        ></kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
          field="LastName"
          title="Last name"
          [width]="220"
        ></kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
          field="ClientName"
          title="Clients"
          [width]="220"
        ></kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
          field="IsActive"
          title="Status"
          [width]="220"
        >
          <ng-template kendoGridCellTemplate let-data>
            <kendo-switch
              [(ngModel)]="data.IsActive"
              (click)="toggleSelection(data)"
              [disabled]="data.IsMasterUser == true"
              onLabel="Active"
              offLabel="Inactive"
              style="width: 85px"
            >
            </kendo-switch>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          [headerStyle]="{ 'font-weight': 'bold', color: '#d04a02' }"
          field="EmailId"
          title="Email ID"
          [width]="220"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<!-- Delete popup -->
<kendo-dialog
  *ngIf="opened"
  (close)="close('cancel')"
  [minWidth]="200"
  [width]="500"
>
  <kendo-dialog-titlebar (close)="close('cancel')">
    <div style="font-size: 20px; line-height: 1.3em">Please confirm</div>
  </kendo-dialog-titlebar>
  <div style="display: flex">
    <p>
      <span
        class="Appkit4-icon icon-circle-warning-outline"
        style="font-size: 24px; color: #d04a02"
      ></span>
    </p>
    <p style="margin-left: 10px; font-size: medium">
      Are you sure you want to permanently delete this user?
    </p>
  </div>
  <!-- <p style="margin: 30px; text-align: center;"></p> -->
  <!-- Click to Actions -->
  <div class="clickToActions" style="padding: 10px 0px 0px 0px">
    <ap-button
      [btnType]="'secondary'"
      [label]="'No'"
      (click)="close('cancel')"
      class="cancelButton"
      style="width: 100px"
    ></ap-button>
    <ap-button
      [btnType]="'primary'"
      [label]="'Yes'"
      themeColor="primary"
      class="okButton"
      style="margin-right: 6px"
      (click)="onAction()"
    ></ap-button>
  </div>
</kendo-dialog>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";

@NgModule({
  declarations: [],
  exports: [
    CommonModule,
    BrowserModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    DropDownsModule

  ]
})
export class TelerikAngularModule { }

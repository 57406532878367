import { Component, OnInit, ViewChild } from '@angular/core';
import { MyGlobalObject } from 'src/app/common/myglobalobject';
import { GlobalService } from 'src/app/common/globalservice/globalservice.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  DataBindingDirective,
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { MatDialogRef } from '@angular/material/dialog';
import { GenericdialogComponent } from 'src/app/common/dialogs/genericdialog/genericdialog.component';
import { FabPositionMode } from '@progress/kendo-angular-buttons';
import {
  orderBy,
  process,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | any;
  public clientsList: any[] = [];
  dialogRef!: MatDialogRef<GenericdialogComponent>;
  public checked = true;
  public mySelection: string[] = [];
  public readonly = true;
  public positionMode: FabPositionMode = 'fixed';
  constructor(
    private globalService: GlobalService,
    private router: Router,
    public toastr: ToastrManager,
    public myglobalobj: MyGlobalObject
  ) {
    this.loadclientsList();
  }

  ngOnInit(): void {
    this.fetchClientsList();

    this.gridView = this.clientsList;
  }

  // sorting code

  public sort: SortDescriptor[] = [
    {
      field: 'ClientEntityName',
      dir: 'asc',
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadclientsList();
  }

  // pagination code

  public gridView: GridDataResult | any;

  //public clientsList: any[] = [];
  public pageSize = 15;
  public skip = 0;

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadclientsList();
  }

  private loadclientsList(): void {
    this.gridView = {
      data: orderBy(
        this.clientsList.slice(this.skip, this.skip + this.pageSize),
        this.sort
      ),
      total: this.clientsList.length,
    };
  }

  //search grid
  public onFilter(event: { originEvent: any; queryStr: string }): void {
    const inputValue = event.queryStr;
    this.gridView = process(this.clientsList, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'ClientEntityName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'Status',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'DBName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'DBstatus',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'actions',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;
    if (inputValue == '') {
      this.loadclientsList();
    }
    this.dataBinding.skip = 0;
  }

  // filters code
  public state: State = {
    skip: 0,
    take: 5,
    filter: {
      logic: 'and',
      filters: [{ field: 'ClientEntityName', operator: '', value: '' }],
    },
  };
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
  }

  fetchClientsList() {
    this.globalService.fetchAllClients().then((response) => {
      if (response != null && response != undefined)
        this.clientsList = response.filter(
          (a: { IsGenericDb: boolean }) => a.IsGenericDb != true
        );
      this.loadclientsList();
    });
  }
  enableDisableSelection(rowData: any) {
    this.globalService
      .disableClientDetailById(rowData.ClientId)
      .then((res) => {
        if (res) {
          this.toastr.successToastr('Updated successfully', 'Success');
          this.fetchClientsList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  addClient() {
    this.globalService.navigateTo('addclient');
  }
  editClient(clientDetails: any) {
    const link = ['/addclient', clientDetails.ClientId];
    this.router.navigate(link);
  }
}

import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { GlobalService } from '../globalservice/globalservice.service';
import { MyGlobalObject } from '../myglobalobject';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  userDetails: any = null;

  constructor(
    private authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private router: Router,
    public myGlobalObject: MyGlobalObject
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const url = state.url;
    const str = url.split('/');
    const page = str[1];
    return this.checkAuthorization(page);
  }
  async checkAuthorization(page: any): Promise<boolean> {
    let result = false;
    let cookie: any = localStorage.getItem('OPENAMSESSION');
    if (
      cookie == 'undefined' ||
      cookie == null ||
      cookie === '' ||
      cookie === 'null'
    ) {
      const response = await this.authenticationService
        .checkAuthentication()
        .toPromise();
      localStorage.setItem('OPENAMSESSION', JSON.stringify(response));
      localStorage.setItem('tokenCreated', Date.now().toString());
    }
    if (
      typeof this.myGlobalObject.userDetailsEnlightenAdmin === 'undefined' ||
      this.myGlobalObject.userDetailsEnlightenAdmin == undefined
    ) {
      await this.globalService
        .getLoggedUserInfo()
        .then((response) => {
          if (response) {
            if (response == 'Super Admin' || response == 'Admin') {
              this.myGlobalObject.userDetailsEnlightenAdmin = response;
              result = true;
            } else {
              this.myGlobalObject.authorizationErrorMessage = response;
              this.router.navigate(['/unauthorized']);
              result = false;
            }
          } else {
            // User doesn't have roles assigned

            this.router.navigate(['/unauthorized']);
            result = false;
          }
        })
        .catch((error) => {
          this.router.navigate(['/unauthorized']);
          result = false;
        });
    } else {
      result = true;
    }
    return result;
  }
}

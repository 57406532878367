import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './common/modules/material.module';
import { TelerikAngularModule } from './common/modules/telerikangular.module';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersComponent } from './usermanagement/users/users.component';
import { ClientsComponent } from './clientmanagement/clients/clients.component';
import { AdduserComponent } from './usermanagement/AddUser/adduser/adduser.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ToastrModule } from 'ng6-toastr-notifications';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { UpdateuserComponent } from './usermanagement/updateuser/updateuser.component';
import { MyGlobalObject } from './common/myglobalobject';
import { GlobalService } from './common/globalservice/globalservice.service';
import { AddclientComponent } from './clientmanagement/addclient/addclient.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonModule } from '@appkit4/angular-components/button';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { MenusModule, MenuModule } from '@progress/kendo-angular-menu';
import { SearchModule } from '@appkit4/angular-components/search';
import { HelpComponent } from './help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    UsersComponent,
    ClientsComponent,
    AdduserComponent,
    UpdateuserComponent,
    AddclientComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    TelerikAngularModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    DropDownsModule,
    GridModule,
    IconsModule,
    DialogsModule,
    ButtonModule,
    NavigationModule,
    MenusModule,
    MenuModule,
    SearchModule,
  ],
  providers: [MyGlobalObject, GlobalService],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component } from '@angular/core';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { Router } from '@angular/router';
import { AuthenticationService } from './common/authentication/authentication.service';
import { GlobalService } from './common/globalservice/globalservice.service';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MyGlobalObject } from './common/myglobalobject';
import { Idle, DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource, StorageInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import getPkce from 'oauth-pkce';
import { HelpComponent } from './help/help.component';
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
  WindowService,
} from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'EnlightenAdminPortal';
  user: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing: any;
  initials: any;
  constructor(
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    public myGlobalObject: MyGlobalObject,
    private globalService: GlobalService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private dialogService: DialogService
  ) {
    // sets an idle timeout of 900 seconds.
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 900 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(this.createCustomInterruptSources(null));
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      localStorage.removeItem('OPENAMSESSION');
      localStorage.removeItem('tokenCreated');
      window.location.href = environment.openam.logoutUrl;
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });
    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.reset();
    //this.authenticationService.checkAuthentication();
  }
  createCustomInterruptSources(options:any) {
    return [
        new DocumentInterruptSource('keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll', options),
        new StorageInterruptSource(options)
    ];
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  ngOnInit(): void {
    var createdTime: any;
    this.reset();
    createdTime = localStorage.getItem('tokenCreated')
      ? localStorage.getItem('tokenCreated')
      : ' ';
    var challengeVerifier = { verifier: '', challenge: '' };
    var now = Date.now().toString();
    var a: any = new Date(parseInt(createdTime));
    var b: any = new Date();
    var check: any = a - b;
    var diff;
    var token: any = localStorage.getItem('OPENAMSESSION');
    if (token != 'undefined') {
      diff = Math.abs(check) / (60 * 60 * 1000) > 0.25;
    }
    if (token != 'undefined' && diff) {
      localStorage.removeItem('OPENAMSESSION');
      localStorage.removeItem('tokenCreated');
      getPkce(50, (error, { verifier, challenge }) => {
        if (!error) {
          // console.log({ verifier, challenge });
          challengeVerifier = { verifier, challenge };
          //console.log(challengeVerifier);
          localStorage.setItem('verifier', challengeVerifier.verifier);
          this.authenticationService.performLogin(challengeVerifier.challenge);
        }
      });
    }
    this.authenticationService
      .checkAuthentication()
      .subscribe((result: any) => {
        //this.cookieService.set('OPENAMSESSION', JSON.stringify(result));
        localStorage.setItem('OPENAMSESSION', JSON.stringify(result));
        localStorage.setItem('tokenCreated', Date.now().toString());
        this.authenticationService.getUserInfo(result).then((response) => {
          this.user = response;
          this.initials =
            this.user.given_name.charAt(0).toUpperCase() +
            this.user.family_name.charAt(0).toUpperCase();
          this.myGlobalObject.loggedinUserDetail = response;
        });
      });
  }
  helpClick() {
    const dialogRef = this.dialogService.open({
      content: HelpComponent,
      width: 500,
      height: 'auto',
    });
  }
  logout(): void {
    // Logout  OpenAM session
    // Hard  OpenAM token as logout does not clears.
    //this.cookieService.set('OPENAMSESSION', '');
    localStorage.removeItem('OPENAMSESSION');
    //this.cookieService.delete('OPENAMSESSION');
    window.location.href = environment.openam.logoutUrl;
  }
  public handleSelectionChange(event: MenuSelectEvent): void {
    if (event.item.text == 'Users') this.router.navigate(['/users']);
    else if (event.item.text == 'Clients') this.router.navigate(['/clients']);
  }
  public clickEnlightenPortal(event: MenuSelectEvent): void {
    if (event.item.text === 'Transaction Data Assist portal') {
      //window.location.href = environment.openam.enlightenPortal;
      window.open(environment.openam.enlightenPortal, '_blank');
    }
  }
}

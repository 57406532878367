import { Injectable } from '@angular/core';


@Injectable()

export class MyGlobalObject {
  
  public labelConfigs: any;
  showSpinner: boolean;
  viewUserMailId:any;
  loggedinUserDetail: any;
  userDetailsEnlightenAdmin:any;
  authorizationErrorMessage:any;
  BackUpAdmin:any;
  public constructor() {    
    this.showSpinner = false;
    this.viewUserMailId="";
    this.authorizationErrorMessage="You don't have required permission to access the Application.Please contact Customer Support.";
  }

}
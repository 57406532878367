<mat-toolbar class="pwc-background-toolbar">
    <mat-toolbar-row>
      
      
      <span class="menu-spacer"></span>
      <div>
        <a mat-button class="pwc-text-white noborder" routerLinkActive="active" [routerLink]="'/users'"> Users </a>
        <a mat-button class="pwc-text-white noborder" routerLinkActive="active" [routerLink]="'/clients'"> Clients </a>
        
  
      </div>
    </mat-toolbar-row>
  
    
  </mat-toolbar>